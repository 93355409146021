import * as React from "react";
import Paper from "@mui/material/Paper";
import { dateTime } from "../../hooks/dateFormatter";
import { useTranslation } from "react-i18next";
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledPaper = styled(Paper)(({ theme }) => ({
  borderRadius: 12,
  boxShadow: theme.shadows[3],
  padding: theme.spacing(2),
  margin: theme.spacing(2, 0),
  "@media (max-width: 768px)": {
    boxShadow: "none",
  },
}));

export default function TransactionList({ data }) {
  const { t } = useTranslation();

  return (
    <StyledPaper>
      <List>
        {data?.map((transaction, index) => (
          <React.Fragment key={transaction.guest || index}>
            <ListItem>
              <ListItemText
                primary={transaction.guest}
                secondary={
                  <Box>
                    {transaction.transaction_type ? (
                      <Typography variant="body2">
                        {t("Payment type")}: {transaction.transaction_type}
                      </Typography>
                    ) : null}
                    {transaction.transaction_date ? (
                      <Typography variant="body2">
                        {t("Payment time")}:{" "}
                        {dateTime(transaction.transaction_date)}
                      </Typography>
                    ) : null}
                    {transaction.debit ? (
                      <Typography variant="body2">
                        {t("Debit")}: {transaction.debit}
                      </Typography>
                    ) : null}
                    {transaction.credit ? (
                      <Typography variant="body2">
                        {t("Credit")}: {transaction.credit}
                      </Typography>
                    ) : null}
                    {transaction.comments ? (
                      <Typography variant="body2">
                        {t("Comments")}: {transaction.comments}
                      </Typography>
                    ) : null}
                  </Box>
                }
              />
            </ListItem>
            {index < data.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </List>
    </StyledPaper>
  );
}
